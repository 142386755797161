<template>
  <div>
    <portal to="body-top">
      <div>
        <h3 class="text-center font-weight-bolder mb-2">
          {{ `Fulfillment for Order ${'RO-1024'}` }}
        </h3>
      </div>
    </portal>
    <quote-status-bar
      class="mb-1"
      :quote-status-bar="quoteStatusBar"
      :item="quoteStatus"
    />
    <div>
      <div class="d-flex align-items-center">
        <div class="w-50 bg-white rounded mr-1">
          <h1 class="font-weight-bolder font-medium-5 m-1">
            {{ 'Dispatch' }}
          </h1>
          <div>
            <span
              v-for="(item, index) of items"
              :key="item.title"
              class="d-flex align-items-center justify-content-start mx-1 mb-1"
            >
              <span
                class="font-weight-bolder"
                style="width: 40%"
              >{{ item.title }}</span>
              <span :class="`${index === items.length-1 || index === 0 ? 'font-weight-bolder' : ''}`">{{ item.subject }}</span>
            </span>
          </div>
        </div>
        <div class="w-50 bg-white rounded">
          <h1 class="font-weight-bolder font-medium-5 m-1">
            {{ 'Return' }}
          </h1>
          <div>
            <span
              v-for="(item, index) of items"
              :key="item.title"
              class="d-flex align-items-center justify-content-start mx-1 mb-1"
            >
              <span
                class="font-weight-bolder"
                style="width: 40%"
              >{{ item.title }}</span>
              <span :class="`${index === items.length-1 || index === 0 ? 'font-weight-bolder' : ''}`">{{ item.subject }}</span>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="w-100 bg-white rounded mt-1">
          <h1 class="font-weight-bolder font-medium-5 p-1">
            {{ 'Order Information' }}
          </h1>
          <b-row class="d-flex align-items-center flex-wrap w-100">
            <b-col
              v-for="(item, index) of orderInformation"
              :key="item.title"
              md="6"
              class="d-flex align-items-center justify-content-start"
            >
              <span
                class="font-weight-bolder mb-1 pl-1"
                style="width: 40%"
              >{{ item.title }}</span>
              <span class="ml-1">
                <span v-if="index === 0">
                  <feather-icon
                    icon="LTickIcon"
                    size="16"
                  />
                </span>
                {{ item.subject }}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="field of ['warehouseNotes', 'orderNotes']"
              :key="field"
              md="6"
            >
              <component
                :is="warehousePackFields[field].type"
                style="width: 95%"
                v-bind="getProps(field, warehousePackFields)"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1 d-flex align-items-center">
          <div class="bg-white rounded w-50 mr-1">
            <h1 class="font-weight-bolder font-medium-5 m-1">
              {{ 'Assets' }}
            </h1>
            <l-table-list-collector
              ref="lTableRef"
              :is-searchable="false"
              :module-name="MODULE_NAME"
              :table-columns="packItemTableColumns"
              :fetched-data="packedItemsArray"
              class="mx-1"
            >
              <template #cell(state)="{data}">
                <feather-icon
                  icon="LPickIcon"
                  size="18"
                  class="ml-2"
                  style="color: green"
                />
              </template>
            </l-table-list-collector>
          </div>
          <div class="bg-white rounded w-50">
            <h1 class="font-weight-bolder font-medium-5 m-1">
              {{ 'Containers' }}
            </h1>
            <l-table-list-collector
              ref="lTableRef"
              :is-searchable="false"
              :module-name="MODULE_NAME"
              :table-columns="packItemTableColumns"
              :fetched-data="packedItemsArray"
              class="mx-1"
            >
              <template #cell(state)="{data}">
                <feather-icon
                  icon="LPickIcon"
                  size="18"
                  class="ml-2"
                  style="color: green"
                />
              </template>
            </l-table-list-collector>
          </div>
        </div>
      </div>
    </div>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div
          class="d-flex"
          style="gap: 8px"
        >
          <b-button
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
          >
            {{ $t('Back to List') }}
          </b-button>
        </div>
        <div
          class="d-flex"
          style="gap: 8px"
        >
          <b-button
            variant="secondary"
            disabled="true"
            class="font-medium-1 font-weight-bold px-3"
          >
            <feather-icon icon="LPrintIcon" />
            {{ $t('Print Pack List') }}
          </b-button>
          <b-button
            variant="secondary"
            disabled="true"
            class="font-medium-1 font-weight-bold px-3"
          >
            <feather-icon icon="LPrintIcon" />
            {{ $t('Print Labels') }}
          </b-button>
          <b-button
            variant="secondary"
            class="font-medium-1 font-weight-bold px-3"
          >
            <feather-icon icon="LBoxIcon" />
            {{ $t('Dispatch') }}
          </b-button>
        </div>
      </div>
    </portal>
  </div>
</template>
<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { getProps } from '@core/utils/utils'
import QuoteStatusBar from '@/views/main/warehouse/view/pack/components/use-as-global/QuoteStatusBar.vue'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from '../config'

export default {
  name: 'PackCreate',
  components: {
    LTableListCollector,
    QuoteStatusBar,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      quoteStatus: {
        state: 1,
        status: 1,
      },
      items: [
        { title: 'Date', subject: 'August 9, 2022, 2:00 pm' },
        { title: 'Method', subject: 'Warehouse Pick up' },
        { title: 'Picked By', subject: 'User2, User 3' },
        { title: 'Dispatch Location', subject: 'Brooklyn Warehouse' },
      ],
      orderInformation: [
        { title: 'Customer', subject: 'John Doe Films, Inc.' },
        { title: 'Agent', subject: 'User 3' },
        { title: 'Placed by', subject: 'Trisha Ziff' },
        { title: 'Event Reference', subject: 'Zoe Deschannel Shoot San Diego' },
      ],
      packedItemsArray: [
        {
          state: true,
          name: 'FUJIFILM X-H2S Mirrorless',
          id: '63453124',
        },
        {
          state: true,
          name: 'FUJIFILM X-H2S Mirrorless',
          id: '63453560',
        },
        {
          state: true,
          name: 'FUJIFILM X-H2S Mirrorless',
          id: '634537845',
        },
        {
          state: false,
          name: 'FUJIFILM X-H2S Mirrorless',
          id: '63453544',
        },
      ],
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  setup() {
    const {
      warehousePackFields, MODULE_NAME, packItemTableColumns, quoteStatusBar,
    } = config()

    return {
      packItemTableColumns,
      warehousePackFields,
      quoteStatusBar,
      MODULE_NAME,
      getProps,
    }
  },
}
</script>
